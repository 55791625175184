import { CONSTANTS } from "@pushprotocol/restapi";
import { IHighlightCardHead, INavLink, IQuestionCard } from "@/app/types";

export const CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID!;
export const DHIVE_ENV = process.env.NEXT_PUBLIC_DHIVE_ENV!;
export const BASE_URL =
  DHIVE_ENV === "prod" ? "https://dhive.io" : "https://stg.dhive.io";
export const DHIVE_CHANNEL = process.env.NEXT_PUBLIC_DHIVE_CHANNEL!;
export const PUSH_ENV =
  DHIVE_ENV === "prod" ? CONSTANTS.ENV.PROD : CONSTANTS.ENV.STAGING;
export const LOGO_URL =
  "https://vtiuptzrrtudacifnywl.supabase.co/storage/v1/object/public/dao-avatars/dhive.svg";

export const HIGHLIGHTS: IHighlightCardHead[] = [
  {
    id: "overview",
    title: "Overview",
    href: "",
  },
  {
    id: "active",
    title: "Active proposals",
    href: "/proposals?status=active",
  },
  {
    id: "closed",
    title: "Latest results",
    href: "/proposals?status=closed",
  },
];

export const NAVIGATION_LINKS: INavLink[] = [
  {
    id: "home",
    label: "Home",
    href: "",
  },
  {
    id: "faq",
    label: "FAQ",
    href: "/faq",
  },
  {
    id: "contact",
    label: "Contact",
    href: "mailto:contact@dhive.io",
  },
];

export const RECOMMENDED_LINKS: INavLink[] = [
  {
    id: "active",
    label: "Active proposals",
    href: "/proposals?status=active",
  },
  {
    id: "results",
    label: "Latest results",
    href: "/proposals?status=closed",
  },
];

export const SOCIAL_LINKS: INavLink[] = [
  {
    id: "twitter",
    label: "X / Twitter",
    href: "https://twitter.com/DhiveDotIo",
  },
  {
    id: "github",
    label: "Github",
    href: "https://github.com/Moona-Lisa",
  },
];

export const DISCLAIMER_TEXT =
  "The information presented on our website, including links to external sites, forums, social media, and other platforms, is intended solely for general informational purposes. It's sourced from third parties, and while we strive for accuracy, we cannot guarantee the reliability, completeness, or timeliness of any information offered. None of the content should be considered financial or legal advice or tailored specifically for reliance in decision-making. Utilizing our content is at your own discretion and risk. We advise conducting thorough personal research and seeking professional consultation before engaging in any form of decision-making that involves financial risks.";

export const WARNING_TEXT =
  "Exercise caution when exploring DAO proposals. Proposals can be submitted by any member of the community so there's an inherent risk of encountering scams or deceptive links. Always critically assess the validity of each proposal and its links before taking action.";

export const ACCOUNT_TABS = [
  {
    id: "account",
    href: "/",
    label: "Account",
    description: "View your account and web3 identities",
  },
  {
    id: "notifications",
    href: "/notifications",
    label: "Notifications",
    description: "Manage your notification settings",
  },
];

export const PROFILE_TABS = [
  {
    id: "overview",
    href: "/",
    label: "Overview",
  },
  {
    id: "votes",
    href: "/votes",
    label: "Votes",
  },
  {
    id: "identities",
    href: "/identities",
    label: "Identities",
  },
];

export const PROPOSAL_TABS = [
  {
    id: "overview",
    href: "/",
    label: "Overview",
  },
  {
    id: "voters",
    href: "/voters",
    label: "Voters",
  },
  {
    id: "results",
    href: "/results",
    label: "Results",
  },
];

export const DAO_TABS = [
  {
    id: "proposals",
    href: "/",
    label: "Proposals",
  },
  {
    id: "holders",
    href: "/holders",
    label: "Holders",
  },
  {
    id: "delegates",
    href: "/delegates",
    label: "Delegates",
  },
];

export const PROPOSALS_TABS = [
  {
    id: "all",
    href: "/proposals",
    label: "All",
    title: "All proposals",
    description: "Explore the list of all proposals submitted by DAOs",
  },
  {
    id: "active",
    href: "/proposals?status=active",
    label: "Active",
    title: "Active proposals",
    description: "Explore the list of active proposals submitted by DAOs",
  },
  {
    id: "latest",
    href: "/proposals?status=closed",
    label: "Closed",
    title: "Closed proposals",
    description: "Explore the list of closed proposals submitted by DAOs",
  },
];

export const COLORS: { [key: string]: string } = {
  ens: "#5198FF",
  lens: "#69C372",
  farcaster: "#8862D0",
  unstoppabledomains: "#0F67FE",
  hey: "#FC3F61",
  github: "#010409",
  discord: "#5865F2",
  website: "#000000",
  twitter: "#000000",
  basenames: "#0052FF",
  linea: "#57C2DD",
};

export const COLOR_PALETTE = [
  "#8DF9A0",
  "#ff5765",
  "#D0D4DC",
  "#9C52FF",
  "#8be5f9",
  "#FF8DF4",
  "#FFE03A",
  "#8fa2ff",
  "#f9b58b",
  "#70ffc8",
  "#9ff98b",
  "#948bf9",
  "#ac8bf9",
  "#8bbcf9",
  "#c08bf9",
  "#b18bf9",
  "#8bf9e3",
  "#8bf9dd",
  "#e38bf9",
  "#f98bec",
  "#d0f98b",
  "#8baaf9",
  "#8bf9a1",
  "#f98bba",
  "#8bb7f9",
  "#f9968b",
  "#e8f98b",
  "#aa8bf9",
  "#f98bd0",
  "#8bf9cd",
  "#8bf9a1",
  "#f98bb5",
  "#92f98b",
  "#8bf9aa",
  "#f98baa",
  "#dc8bf9",
  "#8bcbf9",
  "#8bf9e8",
  "#8bc5f9",
  "#9df98b",
  "#e58bf9",
  "#f98baf",
  "#f78bf9",
  "#f9da8b",
  "#8bc2f9",
  "#b38bf9",
  "#f98bc0",
  "#8bf9cd",
  "#8bf999",
  "#a38bf9",
  "#8bb5f9",
  "#8e8bf9",
  "#f9c78b",
  "#8bf9b3",
  "#8bf9aa",
  "#8bf9b3",
  "#8bf9e1",
  "#9ff98b",
  "#99f98b",
  "#8baef9",
  "#f98bb9",
  "#8bf9a1",
  "#f98bae",
  "#8bf98d",
  "#8bc0f9",
  "#d2f98b",
  "#8bbcf9",
  "#ea8bf9",
  "#8bc9f9",
  "#e3f98b",
  "#8bf9b9",
  "#8bf9bc",
  "#c58bf9",
  "#cd8bf9",
  "#cff98b",
  "#8bc7f9",
  "#f98bac",
  "#f58bf9",
  "#8bb5f9",
  "#f98bd8",
  "#8bf9d0",
  "#bef98b",
  "#8bc4f9",
  "#f98bd6",
  "#f98b90",
  "#f98b8b",
  "#dcf98b",
  "#f98bc4",
  "#d28bf9",
  "#f98bf9",
];

export const COLOR_PALETTE_PROPOSAL: { [key: string]: string } = {
  onchain: "#8be5f9",
  offchain: "#f9b58b",
  pending: "#D0D4DC",
  active: "#8DF9A0",
  closed: "#8fa2ff",
};

export const LOGO_COLORS = [
  "#FF8DF4",
  "#8DF9A0",
  "#9C52FF",
  "#FFE03A",
  "#8dd5f9",
  "#ff933a",
];

export const QUESTIONS_LIST: IQuestionCard[] = [
  {
    id: "1",
    question: "What is a DAO?",
    answer:
      "A DAO, or Decentralized Autonomous Organization, is an autonomous entity that operates through smart contracts on a blockchain. It's governed by its members rather than a centralized authority and executes predefined rules without the need for intermediaries.",
  },
  {
    id: "2",
    question: "How does a DAO function?",
    answer:
      "A DAO functions through smart contracts, which are self-executing contracts with the terms of the agreement directly written into code. Members of the DAO interact with these contracts to vote on proposals, allocate funds, and execute decisions collectively, without the need for a central authority.",
  },
  {
    id: "3",
    question: "What are the benefits of participating in a DAO?",
    answer:
      "Participating in a DAO offers several benefits, including transparency, as all transactions and decisions are recorded on the blockchain for anyone to verify. It also enables decentralized decision-making, giving members a direct say in the organization's operations and resource allocation. Additionally, DAOs often provide opportunities for passive income through token ownership and governance rewards.",
  },
  {
    id: "4",
    question: "How can one join or create a DAO?",
    answer:
      "Joining a DAO typically involves acquiring the native tokens of the organization and becoming a member through a process defined by the DAO's rules. Creating a DAO involves designing smart contracts, defining governance structures, and launching the organization's token. Platforms like Ethereum provide tools and frameworks for creating and interacting with DAOs.",
  },
];

export const DAO_CRITERIA = {
  proposal_frequency_rate: {
    label: "Proposal Frequency Rate",
    description:
      "Measures the average number of proposals submitted per month since the inception of the DAO.",
  },
  proposal_success_rate: {
    label: "Proposal Success Rate",
    description:
      "The percentage of proposals that successfully pass by meeting the required quorum relative to the total number of proposals submitted.",
  },
  voter_participation_ratio: {
    label: "Voter Participation Ratio",
    description:
      "The average ratio of voters to token holders for each proposal, providing an indication of how engaged the community is.",
  },
  votes_cast_ratio: {
    label: "Votes Cast Ratio",
    description:
      "Measures the average ratio of votes cast to the total tokens held at the time of each proposal.",
  },
  voting_distribution_score: {
    label: "Voting Power Distribution",
    description:
      "Measures the distribution of voting power among token holders, aiming for an equitable distribution.",
  },
  token_distribution_score: {
    label: "Token Holdings Distribution",
    description:
      "Measures the distribution of tokens among token holders, aiming for equitable distribution.",
  },
};

export const CRITERIA_LABELS = [
  "Proposal Frequency Rate",
  "Proposal Success Rate",
  "Voter Participation Ratio",
  "Votes Cast Ratio",
  "Voting Power Distribution",
  "Token Holdings Distribution",
];

export const IMAGE_PLACEHOLDER =
  "data:image/gif;base64,R0lGODlhAQABAPAAALja/////yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==";

export const SHORT_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
