import { useEffect } from "react";
import styles from "./styles.module.css";
import Asset from "@/app/components/Assets";

export default function AlertModal({
  modal,
  modalType,
  modalData,
  handleModal,
}: {
  modal: boolean;
  modalType: string;
  modalData: any;
  handleModal: (type: string | null, modalData: any) => void;
}) {
  const color = modalType === "success" ? "#008000" : "#ca0000";
  const iconId = modalType === "success" ? "checkmark" : "close";

  const closeModal = () => {
    handleModal(null, null);
  };

  // Effect with timeout to close the modal
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleModal(null, null);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [handleModal]);

  return (
    <>
      {modal && (
        <div className={styles.alertModal}>
          <div
            className={styles.alertModalWrapper}
            style={{ backgroundColor: `${color}05` }}
          >
            <Asset type={"icon"} id={iconId} color={`${color}80`} size={20} />
            <span>{modalData}</span>
            <div onClick={closeModal}>
              <Asset
                type={"icon"}
                id={"close"}
                color={"var(--medium)"}
                size={20}
                asAvatar={true}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
